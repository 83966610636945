<template>
  <div>
    <v-app-bar
      id="home-back-bar"
      app
      color="primary"
      elevation="1"
      height="50"
    >
      <span @click="Back" class="cursor white--text"><v-icon color="white">mdi-chevron-left</v-icon>KEMBALI</span>
      <v-spacer></v-spacer>
      <!-- <base-img
        :src="require('@/assets/logo-white.png')"
        class="mr-2"
        contain
        max-width="200"
        width="100%"
      /> -->

    </v-app-bar>
   
  </div>
</template>

<script>
  export default {
    name: 'HomeBackBar',
    data: () => ({
      drawer: null,
      items: [
        { id: 'beranda', title: 'Kembali' },
      ],
    }),
    methods: {
      URL (id) {
        this.$router.push({name: id})
      },
      Back () {
        if (this.$route.name === 'kalkulator-kontribusi') {
          localStorage.removeItem('website_users_id')
          localStorage.removeItem('website_users_otherid')
          localStorage.removeItem('website_users_othername')
          window.location.href = '/'
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
